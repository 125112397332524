import React, { Component } from 'react';
import { Link } from 'gatsby';

import './Navbar.scss';

export default class Navbar extends Component {
  state = {
    navbarOpen: false,
    css: 'collapse navbar-collapse text-right',
    links: [
      {
        id: 1,
        path: '/about',
        text: 'ABOUT',
      },
      {
        id: 2,
        path: '/portfolio',
        text: 'PORTFOLIO',
      },
      {
        id: 3,
        path: '/photograph',
        text: 'PHOTOGRAPH',
      },
      {
        id: 4,
        path: '/resume',
        text: 'RESUME',
      },
    ],
  };

  navbarHandler = () => {
    this.state.navbarOpen
      ? this.setState({
          navbarOpen: false,
          css: 'collapse navbar-collapse text-right',
        })
      : this.setState({
          navbarOpen: true,
          css: 'collapse navbar-collapse text-right show',
        });
  };

  render() {
    return (
      <nav className="navbar">
        <Link
          to="/"
          className="navbar-brand link"
          aria-label="Link to HomePage"
        >
          <svg width="64px" height="64px" viewBox="0 0 64 64" fill="#21324D">
            <g id="Group">
              <path
                d="M42.4961843,16.1065991 C41.7787122,23.6392708 35.838631,29.6489918 28.3377949,30.4739081 L28.337945,26.6028316 C33.7153317,25.8191181 37.9511497,21.5161192 38.632909,16.1063249 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M42.5921068,33.5787415 C41.8746346,41.1114131 35.9345535,47.1211342 28.4337174,47.9460505 L28.4338675,44.0749739 C33.8112542,43.2912605 38.0470722,38.9882616 38.7288315,33.5784673 Z"
                id="Combined-Shape"
                transform="translate(35.512868, 40.762315) rotate(270.000000) translate(-35.512868, -40.762315) "
              ></path>
              <rect
                id="Rectangle"
                x="21.2977778"
                y="16.1066667"
                width="3.84"
                height="31.744"
              ></rect>
              <path
                d="M32,0 C49.673112,0 64,14.326888 64,32 C64,49.673112 49.673112,64 32,64 C14.326888,64 0,49.673112 0,32 C0,14.326888 14.326888,0 32,0 Z M32,4.26666667 C16.6833029,4.26666667 4.26666667,16.6833029 4.26666667,32 C4.26666667,47.3166971 16.6833029,59.7333333 32,59.7333333 C47.3166971,59.7333333 59.7333333,47.3166971 59.7333333,32 C59.7333333,16.6833029 47.3166971,4.26666667 32,4.26666667 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </svg>
        </Link>
        {/* <button
          className="navbar-toggler"
          type="button"
          onClick={this.navbarHandler}
        >
          <span className="navbar-toggler-icon" />
        </button> */}

        <div className="navbar-link">
          {this.state.links.map((link) => {
            return (
              <li key={link.id} className="nav-link-item">
                <Link
                  to={link.path}
                  className="nav-link link"
                  activeClassName="active"
                >
                  {link.text}
                </Link>
              </li>
            );
          })}
        </div>
      </nav>
    );
  }
}

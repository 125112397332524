import React, { Component } from 'react';
import './Footer.scss';
import { AiOutlineGithub } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';

export default class Footer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <footer className="footer">
        <div className="footer-line">
          <div className="footer-text">
            <p>© zhukai.me {new Date().getFullYear().toString()}</p>
          </div>
          <div className="footer-icon">
            <div className="icons">
              <li className="icon">
                <a
                  href={this.props.items.githubHandle}
                  className="github-link link"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Link to Github"
                >
                  <AiOutlineGithub size="1.5em" />
                </a>
              </li>
              <li className="icon">
                <a
                  href={this.props.items.linkedinHandle}
                  className="linkedin-link link"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Link to Linkedin"
                >
                  <AiFillLinkedin size="1.5em" />
                </a>
              </li>
              <li className="icon">
                <a
                  href={this.props.items.instagramHandle}
                  className="ig-link link"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Link to Instagram"
                >
                  <AiOutlineInstagram size="1.5em" />
                </a>
              </li>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import './layout.scss';
import Navbar from './Globals/Navbar';
import Footer from './Globals/Footer';

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      {
        profile: contentfulAuthor {
          name
          githubHandle
          instagramHandle
          linkedinHandle
          biography {
            biography
          }
          aboutMe {
            aboutMe
          }
          profilePhoto {
            file {
              url
            }
          }
        }
      }
    `
  );
  return (
    <div className="container-fluid">
      <Navbar />
      {children}
      <Footer items={data.profile} />
    </div>
  );
}
